import { ContentCard } from '@components/ui/ContentCard';

interface Publication {
  id: string;
  title: string;
  journal: string;
  date: string;
  link: string;
  abstract: string;
  image: string;
  type: 'academic' | 'non-academic';
  tags: string[];
}

interface PublicationCardProps {
  publication: Publication;
  showSocial?: boolean;
}

export const PublicationCard = ({ publication, showSocial = false }: PublicationCardProps) => {
  if (!publication) return null;

  return (
    <ContentCard
      id={publication.id}
      title={publication.title}
      description={publication.abstract}
      image={publication.image}
      date={publication.date}
      tags={publication.tags || []}
      externalLink={publication.link}
      categoryLabel={publication.type === 'academic' ? 'Academic' : 'Non-Academic'}
      journal={publication.journal}
      showSocial={showSocial}
    />
  );
};
