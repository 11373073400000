import React from 'react';
import { useTheme } from '../../theme/ThemeContext';
import { Sun, Moon } from 'lucide-react';

/**
 * ThemeToggle Component
 * 
 * A toggle button to switch between dark and light themes
 * Uses icons to represent each theme state
 */
interface ThemeToggleProps {
  className?: string;
}

export const ThemeToggle: React.FC<ThemeToggleProps> = ({ className = '' }) => {
  const { isDark, toggleTheme } = useTheme();
  
  return (
    <button
      onClick={toggleTheme}
      aria-label={`Switch to ${isDark ? 'light' : 'dark'} mode`}
      title={`Switch to ${isDark ? 'light' : 'dark'} mode`}
      className={`p-2 rounded-full text-text hover:bg-surface transition-all ${className}`}
    >
      {isDark ? (
        <Sun className="w-5 h-5 text-primary" />
      ) : (
        <Moon className="w-5 h-5 text-primary" />
      )}
    </button>
  );
};
