// This file provides functions to dynamically load blog posts
import type { BlogPost } from './types';
import { slugify } from '../../utils/slugify';

// Define modules for dynamic loading
const modules = import.meta.glob<{ default: BlogPost }>('./posts/*.ts');

// Function to get all blog posts
export const getBlogPosts = async (): Promise<BlogPost[]> => {
  const blogPosts: BlogPost[] = [];
  
  for (const path in modules) {
    const module = await modules[path]();
    const post = module.default;
    
    // Auto-generate slug from title if not provided
    if (!post.slug) {
      post.slug = slugify(post.title);
    }
    
    blogPosts.push(post);
  }
  
  return blogPosts;
};

// For backward compatibility, but will be loaded only when needed
export const blogPosts: BlogPost[] = [];
