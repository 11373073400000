import React from 'react';
import { Link } from 'react-router-dom';
import { LazyImage } from './LazyImage';
import { SocialShare } from './SocialShare';
import { CardMeta } from './CardMeta';
import { CategoryTag } from './CategoryTag';

interface ContentCardProps {
  // Common props
  id: string;
  title: string;
  description: string;
  image: string;
  date: string;
  tags: string[];
  
  // Navigation/linking
  internalLink?: string;
  externalLink?: string;
  
  // Optional props
  categoryLabel?: string;
  readTime?: string;
  journal?: string;
  showSocial?: boolean;
  
  // Additional links (for projects)
  githubLink?: string;
  demoLink?: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  description,
  image,
  date,
  tags,
  internalLink,
  externalLink,
  categoryLabel,
  readTime,
  journal,
  showSocial = false,
  githubLink,
  demoLink
}) => {
  // Determine if using internal routing or external link
  const isInternalLink = !!internalLink;
  const shareUrl = externalLink || `${window.location.origin}${internalLink}` || '';
  
  // Render the card content without nesting links inside links
  return (
    <article className="bg-surface rounded-xl overflow-hidden border border-border hover:border-primary transition-colors h-full flex flex-col">
      {/* Image Section */}
      <div className="relative h-48 overflow-hidden">
        {isInternalLink ? (
          <Link to={internalLink} className="block group">
            <LazyImage
              src={image}
              alt={title}
              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
            />
            {categoryLabel && <CategoryTag label={categoryLabel} />}
          </Link>
        ) : (
          <a href={externalLink} target="_blank" rel="noopener noreferrer" className="block group">
            <LazyImage
              src={image}
              alt={title}
              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
            />
            {categoryLabel && <CategoryTag label={categoryLabel} />}
          </a>
        )}
      </div>
      
      {/* Content Section */}
      <div className="p-6 flex flex-col flex-1">
        <div className="mb-4">
          {/* Title with appropriate link */}
          {isInternalLink ? (
            <Link to={internalLink} className="text-xl font-bold text-text hover:text-primary transition-colors block mb-3">
              {title}
            </Link>
          ) : (
            <a href={externalLink} target="_blank" rel="noopener noreferrer" className="text-xl font-bold text-text hover:text-primary transition-colors block mb-3">
              {title}
            </a>
          )}
          
          {/* Description with consistent theme text coloring */}
          <p className="text-text/90 line-clamp-3">
            {description}
          </p>
        </div>
        
        <div className="mt-auto pt-4">
          <CardMeta
            date={date}
            readTime={readTime}
            journal={journal}
            tags={tags}
          />
          
          {/* Project links - Only for project cards */}
          {(githubLink || demoLink) && (
            <div className="flex gap-3 mt-4">
              {githubLink && (
                <a 
                  href={githubLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="px-3 py-1.5 text-sm rounded-lg border border-primary/30 text-text hover:bg-primary hover:text-background transition-colors"
                >
                  GitHub
                </a>
              )}
              {demoLink && (
                <a 
                  href={demoLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="px-3 py-1.5 text-sm rounded-lg border border-primary/30 text-text hover:bg-primary hover:text-background transition-colors"
                >
                  Live Demo
                </a>
              )}
            </div>
          )}
          
          {/* Social sharing */}
          {showSocial && shareUrl && (
            <div className="mt-4 pt-4 border-t border-primary/20">
              <SocialShare url={shareUrl} title={title} />
            </div>
          )}
        </div>
      </div>
    </article>
  );
};
