// App.tsx
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Fix component import paths to use relative paths instead of aliases
import { Navigation } from './components/layout/Navigation';
import { Hero } from './components/layout/Hero';
import { About } from './components/sections/About';
import { FeaturedWork } from './components/projects/FeaturedWork';
import { Contact } from './components/sections/Contact';
import { Footer } from './components/layout/Footer';
import { ReadingProgress } from './components/ui/ReadingProgress';
import { BackToTop } from './components/ui/BackToTop';
import { CookieConsent } from './components/ui/CookieConsent';
import { ResourcePreload } from './components/ui/ResourcePreload';
import { Toaster } from 'sonner';
import { lazyLoadComponent, prefetchComponent } from './utils/lazyLoad';
import { SecurityProvider } from './theme/SecurityContext';

// Lazy load page components with the new utility
const Blog = lazyLoadComponent(() => import('./pages/blog/Blog'));
const Projects = lazyLoadComponent(() => import('./pages/portfolio/Projects'));
const ProjectDetails = lazyLoadComponent(() => import('./pages/portfolio/ProjectDetails'));
const Publications = lazyLoadComponent(() => import('./pages/portfolio/Publications'));
const Resume = lazyLoadComponent(() => import('./pages/portfolio/Resume'));
const PrivacyPolicy = lazyLoadComponent(() => import('./pages/legal/PrivacyPolicy'));
const Terms = lazyLoadComponent(() => import('./pages/legal/Terms'));
const CookiePolicy = lazyLoadComponent(() => import('./pages/legal/CookiePolicy'));
const Sitemap = lazyLoadComponent(() => import('./pages/Sitemap'));
const BlogPost = lazyLoadComponent(() => import('./components/blog/BlogPost'));

// You can easily change the image position by changing 'right' to 'left' here
const heroImagePosition = 'right';

const MainContent = () => (
  <>
    <Hero position={heroImagePosition} />
    <About />
    <FeaturedWork />
    <Contact />
  </>
);

const App = () => {
  // Preload components that might be needed soon
  useEffect(() => {
    // Preload based on likely user navigation paths
    const preloadTimeout = setTimeout(() => {
      // After the main content is loaded, preload these components
      prefetchComponent(() => import('./pages/portfolio/Projects'));
      prefetchComponent(() => import('./pages/blog/Blog'));
    }, 2000); // Wait 2 seconds after initial load
    
    return () => clearTimeout(preloadTimeout);
  }, []);
  
  return (
    <HelmetProvider>
      <SecurityProvider>
        <div className="min-h-screen bg-background">
          <ResourcePreload />
          <ReadingProgress />
          <Navigation />

          <main id="main-content">
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetails />} />
              <Route path="/publications" element={<Publications />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/sitemap" element={<Sitemap />} />
            </Routes>
          </main>
          
          <Footer />
          <BackToTop />
          <CookieConsent />
          <Toaster 
            position="bottom-right"
            toastOptions={{
              style: {
                background: '#2B3187',
                color: '#F7F8F8',
                border: '1px solid rgba(43, 49, 135, 0.2)',
              },
            }}
          />
        </div>
      </SecurityProvider>
    </HelmetProvider>
  );
};

export default App;
