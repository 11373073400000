import { useState } from 'react';
import { AnimatedCard } from '@components/ui/AnimatedCard';
import { BlogCard } from '@components/blog/BlogCard';
import { ProjectCard } from '@components/projects/ProjectCard';
import { PublicationCard } from '@components/publications/PublicationCard';
import { blogPosts } from '@data/BlogPostFiles/Blogs_index';
import { projects } from '@data/ProjectFiles/Projects_index';
import { publications } from '@data/PublicationFiles/Publications_index';

type WorkType = 'all' | 'articles' | 'projects' | 'publications';

export const FeaturedWork = () => {
  const [activeType, setActiveType] = useState<WorkType>('all');

  // Helper to sort by date
  const sortByDate = <T extends { date: string }>(items: T[]) =>
    [...items].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // Get the latest 3 items for each category
  const featuredArticles = sortByDate(blogPosts).slice(0, 3);
  const featuredProjects = sortByDate(projects).slice(0, 3);
  const featuredPublications = sortByDate(publications).slice(0, 3);

  // Combine all items and sort for the "all" category
  const allFeatured = sortByDate([
    ...featuredArticles.map(post => ({ ...post, type: 'article' })),
    ...featuredProjects.map(project => ({ ...project, type: 'project' })),
    ...featuredPublications.map(pub => ({ ...pub, type: 'publication' })),
  ]).slice(0, 3);

  const renderContent = () => {
    switch (activeType) {
      case 'articles':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredArticles.map((post, index) => (
              <AnimatedCard key={post.id} delay={index * 0.1}>
                <BlogCard post={post} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      case 'projects':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredProjects.map((project, index) => (
              <AnimatedCard key={project.id} delay={index * 0.1}>
                <ProjectCard project={project} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      case 'publications':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredPublications.map((publication, index) => (
              <AnimatedCard key={publication.id} delay={index * 0.1}>
                <PublicationCard publication={publication} showSocial />
              </AnimatedCard>
            ))}
          </div>
        );
      default:
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {allFeatured.map((item, index) => (
              <AnimatedCard key={item.id} delay={index * 0.1}>
                {item.type === 'article' && (
                  <BlogCard post={item as typeof featuredArticles[0]} showSocial />
                )}
                {item.type === 'project' && (
                  <ProjectCard project={item as typeof featuredProjects[0]} showSocial />
                )}
                {item.type === 'publication' && (
                  <PublicationCard publication={item as typeof featuredPublications[0]} showSocial />
                )}
              </AnimatedCard>
            ))}
          </div>
        );
    }
  };

  return (
    <section className="py-20 px-4">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-text mb-4">Featured Work</h2>
          <p className="text-text/60 text-lg max-w-2xl mx-auto">
            A showcase of my recent projects, publications, and articles
          </p>
        </div>

        <div className="flex justify-center mb-12">
          <div className="inline-flex bg-primary/10 rounded-lg p-1">
            <button
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeType === 'all'
                  ? 'bg-primary text-white'
                  : 'text-text hover:bg-primary hover:text-white'
              }`}
              onClick={() => setActiveType('all')}
            >
              All
            </button>
            <button
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeType === 'articles'
                  ? 'bg-primary text-white'
                  : 'text-text hover:bg-primary hover:text-white'
              }`}
              onClick={() => setActiveType('articles')}
            >
              Articles
            </button>
            <button
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeType === 'projects'
                  ? 'bg-primary text-white'
                  : 'text-text hover:bg-primary hover:text-white'
              }`}
              onClick={() => setActiveType('projects')}
            >
              Projects
            </button>
            <button
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeType === 'publications'
                  ? 'bg-primary text-white'
                  : 'text-text hover:bg-primary hover:text-white'
              }`}
              onClick={() => setActiveType('publications')}
            >
              Publications
            </button>
          </div>
        </div>

        {renderContent()}
      </div>
    </section>
  );
};
