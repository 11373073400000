/**
 * Theme configuration
 * This file defines the theme structure and color values
 * based strictly on the logo colors: #f7f8f8 (white), #2b3187 (deep blue), #0e0f1f (dark blue/black)
 */

// Logo-derived color palette - THE ONLY COLORS ALLOWED
export const logoColors = {
  white: '#f7f8f8',      // Light gray/white - text in dark mode, background in light mode
  accentBlue: '#2b3187', // Deep blue - accent color, focus outlines
  darkBlue: '#0e0f1f',   // Dark blue/black - background in dark mode, text in light mode
}

// Theme interface
export interface Theme {
  colors: {
    background: string;
    text: string;
    textSecondary: string;
    primary: string;
    surface: string;
    border: string;
    error: string;
    success: string;
    // Add tag specific colors for better control
    tagBackground: string;
    tagText: string;
    tagIconColor: string;
    // Add icon specific colors
    iconColor: string;
    iconHover: string;
  };
  typography: {
    fontFamily: string;
    fontSize: {
      xs: string;
      sm: string;
      base: string;
      lg: string;
      xl: string;
      '2xl': string;
      '3xl': string;
    };
    fontWeight: {
      normal: number;
      medium: number;
      bold: number;
    };
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  borderRadius: {
    sm: string;
    md: string;
    lg: string;
    full: string;
  };
  transitions: {
    default: string;
  };
}

/**
 * Dark theme configuration (DEFAULT)
 * Uses darkBlue as background and white as text
 */
export const darkTheme: Theme = {
  colors: {
    background: logoColors.darkBlue,
    text: logoColors.white,
    textSecondary: `rgba(247, 248, 248, 0.7)`,
    primary: logoColors.accentBlue,
    surface: `rgba(43, 49, 135, 0.15)`,
    border: `rgba(43, 49, 135, 0.4)`,
    error: logoColors.accentBlue,
    success: logoColors.accentBlue,
    // Add tag specific colors for better control
    tagBackground: `rgba(43, 49, 135, 0.2)`,
    tagText: logoColors.white,
    tagIconColor: logoColors.accentBlue,
    // Add icon specific colors
    iconColor: logoColors.white,
    iconHover: logoColors.accentBlue,
  },
  typography: {
    fontFamily: '"Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif',
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '2rem',
    },
    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 700,
    },
  },
  spacing: {
    xs: '0.5rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem',
  },
  borderRadius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
    full: '9999px',
  },
  transitions: {
    default: 'all 0.3s ease-in-out',
  },
};

/**
 * Light theme configuration
 * Inverts the dark theme - white as background and darkBlue as text
 */
export const lightTheme: Theme = {
  colors: {
    background: logoColors.white,
    text: logoColors.darkBlue,
    textSecondary: `rgba(14, 15, 31, 0.7)`,
    primary: logoColors.accentBlue,
    surface: `rgba(14, 15, 31, 0.05)`,
    border: `rgba(43, 49, 135, 0.2)`,
    error: logoColors.accentBlue,
    success: logoColors.accentBlue,
    // Add tag specific colors for better control
    tagBackground: `rgba(14, 15, 31, 0.1)`,
    tagText: logoColors.darkBlue,
    tagIconColor: logoColors.accentBlue,
    // Add icon specific colors
    iconColor: logoColors.darkBlue,
    iconHover: logoColors.accentBlue,
  },
  typography: darkTheme.typography,
  spacing: darkTheme.spacing,
  borderRadius: darkTheme.borderRadius,
  transitions: darkTheme.transitions
};

/**
 * Apply theme to document
 * Sets CSS variables based on the selected theme
 */
export const applyTheme = (theme: Theme): void => {
  const root = document.documentElement;
  
  // Apply colors as CSS variables
  Object.entries(theme.colors).forEach(([key, value]) => {
    root.style.setProperty(`--color-${key}`, value);
    
    // For rgba usage, also set RGB values without the # and alpha if it's a hex color
    if (value.startsWith('#')) {
      const hexToRgb = (hex: string): string => {
        // Remove # if present
        hex = hex.replace(/^#/, '');
        
        // Parse hex to RGB
        let r, g, b;
        if (hex.length === 3) {
          r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
          g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
          b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
        } else {
          r = parseInt(hex.substring(0, 2), 16);
          g = parseInt(hex.substring(2, 4), 16);
          b = parseInt(hex.substring(4, 6), 16);
        }
        
        return `${r}, ${g}, ${b}`;
      };
      
      root.style.setProperty(`--color-${key}-rgb`, hexToRgb(value));
    }
  });
};
