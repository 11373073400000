import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyImageProps {
  src: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  sizes?: string;
  quality?: number;
  priority?: boolean;
}

export const LazyImage = ({
  src,
  alt,
  className = '',
  width,
  height,
  sizes = '100vw',
  quality = 75,
  priority = false
}: LazyImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    // Skip intersection observer for priority images
    skip: priority
  });

  // Generate WebP version if not already
  const webpSrc = src.endsWith('.webp') 
    ? src 
    : `${src.replace(/\.(jpe?g|png)$/i, '.webp')}${quality && quality < 100 ? `?q=${quality}` : ''}`;
  
  // For fallback if WebP not supported
  const fallbackSrc = quality && quality < 100 && !src.includes('?') 
    ? `${src}?q=${quality}` 
    : src;
  
  useEffect(() => {
    // For priority images, load immediately
    if (priority) {
      const img = new Image();
      img.src = webpSrc;
      img.onload = () => setIsLoaded(true);
      return;
    }
    
    // Otherwise load when in view
    if (inView) {
      const img = new Image();
      img.src = webpSrc;
      img.onload = () => setIsLoaded(true);
    }
  }, [inView, webpSrc, priority]);

  // If it's a priority image, render immediately without intersection observer
  if (priority) {
    return (
      <div className={`relative overflow-hidden ${className}`}>
        <div
          className={`absolute inset-0 bg-primary/10 blur-xl transition-opacity duration-300 ${
            isLoaded ? 'opacity-0' : 'opacity-100'
          }`}
        />
        <picture>
          <source srcSet={webpSrc} type="image/webp" />
          <source srcSet={fallbackSrc} type={`image/${src.split('.').pop()}`} />
          <img
            src={fallbackSrc}
            alt={alt}
            width={width}
            height={height}
            sizes={sizes}
            loading="eager"
            className={`transition-opacity duration-300 ${
              isLoaded ? 'opacity-100' : 'opacity-0'
            } ${className}`}
          />
        </picture>
      </div>
    );
  }

  return (
    <div ref={ref} className={`relative overflow-hidden ${className}`}>
      {inView && (
        <>
          <div
            className={`absolute inset-0 bg-primary/10 blur-xl transition-opacity duration-300 ${
              isLoaded ? 'opacity-0' : 'opacity-100'
            }`}
          />
          <picture>
            <source srcSet={webpSrc} type="image/webp" />
            <source srcSet={fallbackSrc} type={`image/${src.split('.').pop()}`} />
            <img
              src={fallbackSrc}
              alt={alt}
              width={width}
              height={height}
              sizes={sizes}
              loading="lazy"
              className={`transition-opacity duration-300 ${
                isLoaded ? 'opacity-100' : 'opacity-0'
              } ${className}`}
            />
          </picture>
        </>
      )}
    </div>
  );
};