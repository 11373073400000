import { slugify } from '@utils/slugify';
import { calculateReadTime } from '@utils/readTime';
import { ContentCard } from '@components/ui/ContentCard';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  date: string;
  category: string;
  image: string;
  tags: string[];
  content?: string;
}

interface BlogCardProps {
  post: BlogPost;
  showSocial?: boolean;
}

export const BlogCard = ({ post, showSocial = false }: BlogCardProps) => {
  if (!post?.title) return null;

  const slug = slugify(post.title);
  const readTime = post.content ? `${calculateReadTime(post.content)} min read` : undefined;

  return (
    <ContentCard
      id={post.id}
      title={post.title}
      description={post.excerpt}
      image={post.image}
      date={post.date}
      tags={post.tags || []}
      internalLink={`/blog/${slug}`}
      categoryLabel={post.category}
      readTime={readTime}
      showSocial={showSocial}
    />
  );
};