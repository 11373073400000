import type { Project } from '../../../types';
import StataWorkflowImage from "@Project_images/coding-laptop-coffee.webp";

const stataWorkflow: Project = {
  id: "stata-workflow",
  title: "Stata Workflow and Custom Commands",
  description: "Streamlining econometric research through structured workflows and reusable Stata commands to enhance efficiency, accuracy, and reproducibility.",
  technologies: ["Stata", "Econometric Analysis", "Workflow", "Automation", "Research"],
  image: StataWorkflowImage,
  date: "18 November, 2024",
  content: `
  <div class="project-content space-y-6">
  <h2 class="text-2xl font-bold text-text">Stata Workflow and Custom Commands</h2>
  <p class="text-text leading-relaxed">
    During my work as a research assistant, I developed this workflow to tackle repetitive tasks, standardize outputs, and boost my productivity as a researcher. It served as a practical solution for managing large datasets and ensuring consistent, reproducible results. I hope that by sharing this project, others can benefit from my approach, enabling them to optimize their own research workflows and boost their productivity.
  </p>
  
  <h3 class="text-xl font-semibold text-text">Key Highlights</h3>
  <ul class="list-disc pl-5 space-y-2 text-text">
    <li><strong>Custom Commands:</strong> Automates tasks like variable transformations, regression analysis, and exporting results into Word documents.</li>
    <li><strong>Structured Workflow:</strong> Demonstrates a complete research workflow using the World Bank Enterprise Survey dataset as an example.</li>
    <li><strong>Reproducibility:</strong> Ensures consistency and accuracy by embedding best practices into reusable scripts.</li>
  </ul>

  <h3 class="text-xl font-semibold text-text">Challenges Addressed</h3>
  <p class="text-text leading-relaxed">
    This project tackles key challenges faced by researchers, including:
  </p>
  <ul class="list-disc pl-5 space-y-2 text-text">
    <li>Automating repetitive tasks to save time and reduce errors.</li>
    <li>Standardizing outputs for consistent and reproducible research results.</li>
    <li>Integrating advanced workflows with accessible tools to streamline econometric analysis.</li>
  </ul>

  <p class="text-text leading-relaxed">
    Explore the workflow and custom commands in detail:
    <a
      href="https://github.com/zeinalamah/Stata-Workflow-Custom-Commands"
      target="_blank"
      class="text-primary font-semibold hover:underline"
    >
      View on GitHub
    </a>.
  </p>
</div>
`
};

export default stataWorkflow;

