import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * ResourcePreload Component
 * 
 * This component injects preload links for critical resources
 * like images, fonts, and stylesheets into the document head
 */
export const ResourcePreload = () => {
  // Dynamically add preload links based on the current route
  useEffect(() => {
    // This runs once on mount
    const preloadImage = (src: string, as = 'image', type?: string) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = src;
      link.as = as;
      if (type) link.type = type;
      document.head.appendChild(link);
      
      return () => {
        document.head.removeChild(link);
      };
    };
    
    // Cleanup function to collect all removal functions
    const cleanupFns: Array<() => void> = [];
    
    // Preload critical images for the current path
    const path = window.location.pathname;
    
    if (path === '/' || path === '') {
      // Homepage - preload hero images
      cleanupFns.push(preloadImage('/images/hero-bg-large.webp'));
      cleanupFns.push(preloadImage('/zein-alamah.webp'));
    } else if (path.startsWith('/blog')) {
      // Blog page - preload potential blog images
      cleanupFns.push(preloadImage('/images/blog-header.webp'));
    } else if (path.startsWith('/projects')) {
      // Projects page - preload potential project images
      cleanupFns.push(preloadImage('/images/projects-header.webp'));
    }
    
    // Return cleanup function
    return () => {
      cleanupFns.forEach(fn => fn());
    };
  }, []);
  
  return (
    <Helmet>
      {/* Always preload these critical resources */}
      <link rel="preload" href="/fonts/Inter-var.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
      
      {/* Add preload for hero image on homepage */}
      {window.location.pathname === '/' && (
        <>
          <link rel="preload" href="/images/hero-bg-large.webp" as="image" />
          <link rel="preload" href="/zein-alamah.webp" as="image" />
        </>
      )}
    </Helmet>
  );
};
