import { Link } from 'react-router-dom';
import { Mail, Linkedin, Home, FileText, BookOpen, Code, Shield } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";

// Create a styled footer with stronger color overrides
export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-12 px-4 bg-background border-t border-primary/10">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-10">
          <div>
            <h3 className="text-text font-bold mb-4 text-lg">Navigation</h3>
            <div className="grid gap-y-3">
            <Link 
  to="/"
                onClick={(e) => {
                  if (window.location.pathname === '/') {
                    e.preventDefault();
                    document.getElementById('hero')?.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <Home size={16} />
                <span>Home</span>
              </Link>

              <Link 
                to="/blog"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <BookOpen size={16} />
                <span>Articles</span>
              </Link>
              <Link 
                to="/projects"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <Code size={16} />
                <span>Projects</span>
              </Link>
              <Link 
                to="/publications"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <FileText size={16} />
                <span>Publications</span>
              </Link>
              <Link 
                to="/resume"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <FileText size={16} />
                <span>Resume</span>
              </Link>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4 text-lg">Contact</h3>
            <div className="grid gap-y-3">
              <a 
                href="mailto:zein@zeinalamah.com"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <Mail size={16} />
                <span>zein@zeinalamah.com</span>
              </a>
              <a 
                href="https://www.linkedin.com/in/zein-alamah/"
                target="_blank"
                rel="noopener noreferrer"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <Linkedin size={16} />
                <span>LinkedIn Profile</span>
              </a>
              <a 
                href="https://x.com/zein_alamah"
                target="_blank"
                rel="noopener noreferrer"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <FaXTwitter size={16} />
                <span>X Profile</span>
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4 text-lg">Legal</h3>
            <div className="grid gap-y-3">
              <Link 
                to="/privacy-policy"
                className="!text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Privacy Policy
              </Link>
              <Link 
                to="/terms"
                className="!text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Terms
              </Link>
              <Link 
                to="/cookie-policy"
                className="!text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Cookie Policy
              </Link>
              <button 
                onClick={() => window.manageCookieSettings()}
                className="text-left !text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Manage Cookies
              </button>
              <Link 
                to="/sitemap"
                className="!text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Sitemap
              </Link>
            </div>
          </div>

          <div>
            <h3 className="text-text font-bold mb-4 text-lg">Security</h3>
            <div className="grid gap-y-3">
              <a 
                href="/security.txt"
                className="[&>*]:text-text [&>*]:!opacity-70 hover:[&>*]:!opacity-100 [&]:!text-text [&]:!opacity-70 hover:[&]:!opacity-100 transition-opacity flex items-center gap-2"
              >
                <Shield size={16} />
                <span>Security Policy</span>
              </a>
              <a 
                href="mailto:zein@zeinalamah.com?subject=Security%20Issue"
                className="!text-text !opacity-70 hover:!opacity-100 transition-opacity"
              >
                Report an Issue
              </a>
            </div>
          </div>
        </div>
        
        <div className="text-center !text-text !opacity-70 pt-6 border-t border-primary/10">
          <p>&copy; {currentYear} Zein Alamah. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};