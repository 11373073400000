import { Linkedin, Link as LinkIcon } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";
import { toast } from 'sonner';

interface SocialShareProps {
  url: string;
  title: string;
}

export const SocialShare = ({ url, title }: SocialShareProps) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const shareLinks = {
    twitter: `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Link copied to clipboard');
    } catch (err) {
      toast.error('Failed to copy link');
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="flex items-center gap-4">
      <a
        href={shareLinks.twitter}
        target="_blank"
        rel="noopener noreferrer"
        className="text-text hover:text-primary transition-colors"
        aria-label="Share on X (Twitter)"
      >
        <FaXTwitter size={18} className="text-current" />
      </a>
      <a
        href={shareLinks.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="text-text hover:text-primary transition-colors"
        aria-label="Share on LinkedIn"
      >
        <Linkedin size={20} className="text-current" />
      </a>
      <button
        onClick={copyToClipboard}
        className="text-text hover:text-primary transition-colors"
        aria-label="Copy link"
      >
        <LinkIcon size={20} className="text-current" />
      </button>
    </div>
  );
};