import type { Publication } from '../../../types';
import EtherImage from "@Publication_images/Ether-coin-market-background.webp";

const ethereumAnalysis: Publication = {
  id: 'Price-of-Ether',
  title: "Is the Price of Ether Driven by Demand or Pure Speculation?",
  journal: "Computational Economics",
  date: "12 September, 2024",
  link: "https://link.springer.com/article/10.1007/s10614-024-10658-6",
  abstract: "This research, utilizing weekly data from 2017 to 2023 (298 observations), seeks to answer the question, 'Is the Price of Ether Driven by Demand or Pure Speculation?' It investigates the determinants of Ether price by focusing on the role of Gas price in Wei, Ethereum Network Utilization, and Bitcoin price. The study demonstrates that Network Utilization, indicative of demand, significantly influences Ether’s price, suggesting a demand-driven price dynamic over pure speculation. Conversely, Gas and Bitcoin prices exert a less pronounced impact. Despite the constraints of a specific timeframe and limited variables, the research provides crucial insights into Ether’s pricing dynamics. The revealed dependence of Ether’s price on actual network demand and utilization supports the argument that Ether exhibits commodity-like characteristics, contributing to the ongoing debate on Ether’s status as a commodity or a security. The utility of econometric methodologies, especially the SVAR model, is highlighted in exploring relationships within the Ethereum ecosystem. The study holds significant implications for stakeholders in the Ethereum ecosystem and the broader cryptocurrency market, and it encourages future research to consider additional price determinants and employ diverse econometric models.",
  image: EtherImage,
  type: 'academic',
  tags: ['Cryptocurrency', 'Market Analysis', 'Blockchain Economics']
};

export default ethereumAnalysis;
