import React from 'react';

interface CategoryTagProps {
  label: string;
  className?: string;
}

export const CategoryTag: React.FC<CategoryTagProps> = ({ 
  label, 
  className = ''
}) => {
  // Theme-responsive styling with Tailwind's dark mode variant
  const bgColorClass = 'bg-primary dark:bg-white';
  const textColorClass = 'text-white dark:text-primary';
  
  return (
    <div className={`absolute top-4 left-4 ${bgColorClass} ${textColorClass} px-3 py-1 rounded-full text-sm font-medium shadow-sm backdrop-blur-sm ${className}`}>
      {label}
    </div>
  );
};
