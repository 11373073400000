import React, { createContext, useContext, useState, useEffect } from 'react';
import { generateNonce } from '../utils/security';

interface SecurityContextType {
  nonce: string;
  refreshNonce: () => void;
  trustedDomains: string[];
  isTrustedDomain: (domain: string) => boolean;
}

const SecurityContext = createContext<SecurityContextType | undefined>(undefined);

interface SecurityProviderProps {
  children: React.ReactNode;
}

export const SecurityProvider: React.FC<SecurityProviderProps> = ({ children }) => {
  // Generate a nonce on first render
  const [nonce, setNonce] = useState<string>(() => generateNonce());
  
  // List of trusted domains for external resources
  const trustedDomains = [
    'googletagmanager.com',
    'google-analytics.com',
    'fonts.googleapis.com',
    'fonts.gstatic.com'
  ];
  
  // Refresh the nonce
  const refreshNonce = () => {
    setNonce(generateNonce());
  };
  
  // Check if a domain is trusted
  const isTrustedDomain = (domain: string): boolean => {
    return trustedDomains.some(trusted => 
      domain === trusted || domain.endsWith(`.${trusted}`)
    );
  };
  
  // Refresh nonce periodically for enhanced security
  useEffect(() => {
    const intervalId = setInterval(refreshNonce, 1000 * 60 * 30); // every 30 minutes
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <SecurityContext.Provider 
      value={{ 
        nonce, 
        refreshNonce,
        trustedDomains,
        isTrustedDomain
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};

export const useSecurity = (): SecurityContextType => {
  const context = useContext(SecurityContext);
  if (context === undefined) {
    throw new Error('useSecurity must be used within a SecurityProvider');
  }
  return context;
};
