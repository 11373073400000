import { calculateReadTime } from '@utils/readTime';
import { ContentCard } from '@components/ui/ContentCard';

interface Project {
  id: string;
  title: string;
  description: string;
  technologies: string[];
  image: string;
  github?: string;
  demo?: string;
  date: string;
  content: string;
}

interface ProjectCardProps {
  project: Project;
  showSocial?: boolean;
}

export const ProjectCard = ({ project, showSocial = false }: ProjectCardProps) => {
  const readTime = `${calculateReadTime(project.content)} min read`;

  return (
    <ContentCard
      id={project.id}
      title={project.title}
      description={project.description}
      image={project.image}
      date={project.date}
      tags={project.technologies || []}
      internalLink={`/projects/${project.id}`}
      categoryLabel="Project"
      readTime={readTime}
      showSocial={showSocial}
      githubLink={project.github}
      demoLink={project.demo}
    />
  );
};