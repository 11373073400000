import React, { useEffect } from 'react';
import { Github, Linkedin, Mail, Link as LinkIcon } from 'lucide-react';
import { FaXTwitter } from 'react-icons/fa6';

interface HeroProps {
  position?: 'left' | 'right';
}

export const Hero: React.FC<HeroProps> = ({ position = 'right' }) => {
  // Preload images
  useEffect(() => {
    const preloadImages = () => {
      // Preload hero background images
      const imageSizes = ['small', 'medium', 'large'];
      imageSizes.forEach(size => {
        const img = new Image();
        img.src = `/images/hero-bg-${size}.webp`;
      });
      
      // Preload profile image
      const profileImg = new Image();
      profileImg.src = '/zein-alamah.webp';
    };
    
    preloadImages();
  }, []);

  return (
    <section 
      id="hero" 
      className="relative min-h-screen flex items-center justify-center pt-20 pb-16"
    >
      {/* Background Image with Overlay - Using picture element for responsive images */}
      <div className="absolute inset-0 opacity-25">
        <picture>
          <source 
            media="(max-width: 640px)" 
            srcSet="/images/hero-bg-small.webp"
          />
          <source 
            media="(max-width: 1024px)" 
            srcSet="/images/hero-bg-medium.webp"
          />
          <img
            src="/images/hero-bg-large.webp"
            alt=""
            className="w-full h-full object-cover"
            loading="eager" // Critical above-the-fold image
            // Remove fetchPriority as it's causing React warnings
          />
        </picture>
      </div>
      
      <div className="container px-4 md:px-6 relative z-10">
        <div className={`grid grid-cols-1 md:grid-cols-2 gap-8 items-center ${position === 'left' ? 'md:flex-row-reverse' : ''}`}>
          {/* Text Column */}
          <div className="space-y-6 text-center md:text-left">
            <h1 className="text-5xl md:text-6xl font-bold text-text mb-2">
              Zein Alamah
            </h1>
            <p className="text-xl md:text-2xl text-text/80">
              Strategist | Economist | Data Analyst
            </p>
            <p className="text-lg md:text-xl text-text/70 italic mb-4">
              Generalists Rule The World. Keep Learning.
            </p>

            <p className="text-base md:text-lg text-text/80 max-w-xl mx-auto md:mx-0">
              I leverage expertise in economics, finance, and data analysis to deliver
              actionable strategies that resolve critical challenges and drive sustainable growth.
            </p>

            {/* Social Icons */}
            <div className="flex justify-center md:justify-start gap-4 pt-4">
              <a 
                href="https://linktr.ee/zein.alamah"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text hover:text-primary transition-colors"
                aria-label="Linktree Profile"
              >
                <LinkIcon size={22} />
              </a>
              <a 
                href="https://github.com/zeinalamah"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text hover:text-primary transition-colors"
                aria-label="GitHub Profile"
              >
                <Github size={22} />
              </a>
              <a 
                href="https://www.linkedin.com/in/zein-alamah/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text hover:text-primary transition-colors"
                aria-label="LinkedIn Profile"
              >
                <Linkedin size={22} />
              </a>
              <a 
                href="https://x.com/zein_alamah"
                target="_blank"
                rel="noopener noreferrer"
                className="text-text hover:text-primary transition-colors"
                aria-label="X (Twitter) Profile"
              >
                <FaXTwitter size={20} />
              </a>
              <a 
                href="mailto:zein@zeinalamah.com"
                className="text-text hover:text-primary transition-colors"
                aria-label="Email Contact"
              >
                <Mail size={22} />
              </a>
            </div>
          </div>

          {/* Image Column */}
          <div className="flex justify-center md:justify-end relative">
            <div className="relative w-64 h-64 md:w-80 md:h-80 rounded-full overflow-hidden border-4 border-primary/20 shadow-xl animate-fade-in">
              <img
                src="/zein-alamah.webp"
                alt="Zein Alamah"
                className="object-cover w-full h-full"
              />
            </div>

            {/* Badge - styled like CategoryTag */}
            <div className="absolute -bottom-6 right-0 md:right-12 bg-[rgba(43,49,135,0.85)] text-white rounded-lg shadow-md p-3 animate-fade-in-left">
              <p className="text-sm font-medium">Concordia University</p>
              <p className="text-xs text-white/80">Montreal, Canada</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
