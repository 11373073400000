import type { Publication } from '../../../types';
import WheatImage from "@Publication_images/wheat-field-sunset.webp";

const twitterUncertainty: Publication = {
  id: 'wheat-twitter-uncertainty',
  title: "Does twitter economic uncertainty matter for wheat prices?",
  journal: "Economics Letters",
  date: "2 December, 2023",
  link: "https://www.sciencedirect.com/science/article/abs/pii/S0165176523004895",
  abstract: "This paper investigates the relationship between wheat prices, energy prices (crude oil and natural gas), and economic uncertainty, represented by the Twitter Economic Uncertainty Index (TEU). Despite existing literature emphasizing the significant impact of energy costs on agricultural commodity prices, this research uncovers no significant short-run relationship between the price shocks in the crude oil or natural gas markets and wheat prices. Similarly, fluctuations in the TEU USA do not reliably predict changes in wheat prices. This paper enriches the field by utilizing time series econometric analysis with a Structural Vector Auto-Regressive model with an exogenous variable (SVARX), incorporating four endogenous variables: wheat, crude oil, natural gas, and economic uncertainty from mid-2011 to mid-2022, and an exogenous dummy variable to account for an existing structural break. The findings present new insights into the complex interplay between these variables within a single framework and expand our understanding of the impact of economic uncertainty derived from Twitter data on agricultural commodity prices. Policymakers and researchers are advised to continue exploring these relationships over different periods, geographical contexts, and events to strengthen food security strategies by gaining a nuanced understanding of agricultural commodity prices.",
  image: WheatImage,
  type: 'academic',
  tags: ['Economic Uncertainty', 'Commodity Markets', 'Social Media Analysis']
};

export default twitterUncertainty;
