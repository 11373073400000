import { useState, FormEvent } from 'react';
import { Mail, Linkedin, Send, AlertCircle } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";
import { AnimatedCard } from '../ui/AnimatedCard';
import { useSecurity } from '../../theme/SecurityContext';
import { sanitizeInput } from '../../utils/security';
import { toast } from 'sonner';

export const Contact = () => {
  const { nonce } = useSecurity();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    // Sanitize input before setting state
    setFormData(prev => ({
      ...prev,
      [name]: sanitizeInput(value)
    }));
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Get the form element
      const form = e.currentTarget;
      const formData = new FormData(form);
      
      // Submit the form using fetch - Netlify requires this format
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData as any).toString()
      })
        .then(response => {
          if (response.ok) {
            // Clear form after successful submission
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: ''
            });
            
            setFormSubmitted(true);
            toast.success("Your message has been sent successfully! I'll get back to you soon.");
          } else {
            console.error('Form submission failed:', response);
            toast.error("Failed to send message. Please try again later.");
            throw new Error(`Form submission failed: ${response.status}`);
          }
        })
        .catch(error => {
          console.error('Form submission error:', error);
          toast.error("Failed to send message. Please try again later.");
        });
    } catch (error) {
      console.error('Form submission error:', error);
      const errorMessage = error instanceof Error 
        ? `Error: ${error.message}`
        : 'Failed to send message. Please try again later.';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="py-20 px-4">
      <div className="container mx-auto max-w-5xl">
        <AnimatedCard>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-text mb-8">Get in Touch</h2>
            <p className="text-text/60 text-lg max-w-2xl mx-auto mb-12">
              Have a question, a collaboration proposal, or just want to say hi? Let's connect.
            </p>
            
            <div className="grid md:grid-cols-2 gap-8 mb-12">
              {/* Contact Form */}
              <div className="bg-surface/30 p-6 rounded-lg shadow-sm">
                <h3 className="font-semibold text-lg mb-4 text-left">Send a Message</h3>
                
                {formSubmitted ? (
                  <div className="bg-primary/10 rounded-lg p-6 text-center">
                    <h4 className="text-xl font-medium mb-2">Thank You!</h4>
                    <p className="mb-4">Your message has been received. I'll get back to you as soon as possible.</p>
                    <button 
                      onClick={() => setFormSubmitted(false)}
                      className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
                    >
                      Send Another Message
                    </button>
                  </div>
                ) : (
                  <form 
                    name="contact" 
                    method="POST" 
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit} 
                    className="space-y-4 text-left" 
                    data-nonce={nonce}
                  >
                    {/* Netlify Form Fields - Important! */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="hidden">
                      <label>
                        Don't fill this out if you're human: <input name="bot-field" />
                      </label>
                    </div>
                    
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-text/80 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full px-3 py-2 bg-background border ${errors.name ? 'border-red-500' : 'border-primary/20'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary/30`}
                        maxLength={100}
                        required
                      />
                      {errors.name && (
                        <p className="mt-1 text-sm text-red-500 flex items-center">
                          <AlertCircle size={12} className="mr-1" /> {errors.name}
                        </p>
                      )}
                    </div>
                    
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-text/80 mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-3 py-2 bg-background border ${errors.email ? 'border-red-500' : 'border-primary/20'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary/30`}
                        maxLength={150}
                        required
                      />
                      {errors.email && (
                        <p className="mt-1 text-sm text-red-500 flex items-center">
                          <AlertCircle size={12} className="mr-1" /> {errors.email}
                        </p>
                      )}
                    </div>
                    
                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-text/80 mb-1">
                        Subject (Optional)
                      </label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full px-3 py-2 bg-background border border-primary/20 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/30"
                        maxLength={200}
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-text/80 mb-1">
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={4}
                        className={`w-full px-3 py-2 bg-background border ${errors.message ? 'border-red-500' : 'border-primary/20'} rounded-md focus:outline-none focus:ring-2 focus:ring-primary/30`}
                        maxLength={2000}
                        required
                      />
                      {errors.message && (
                        <p className="mt-1 text-sm text-red-500 flex items-center">
                          <AlertCircle size={12} className="mr-1" /> {errors.message}
                        </p>
                      )}
                    </div>
                    
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-primary hover:bg-primary/90 text-white rounded-lg transition-colors disabled:opacity-70"
                    >
                      {isSubmitting ? (
                        <>
                          <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                          Sending...
                        </>
                      ) : (
                        <>
                          <Send size={16} />
                          Send Message
                        </>
                      )}
                    </button>
                    
                    <p className="text-xs text-text/60 mt-2">
                      Your information is protected by our <a href="/privacy-policy" className="text-primary hover:underline">Privacy Policy</a>.
                    </p>
                  </form>
                )}
              </div>
              
              {/* Connect via Social */}
              <div className="flex flex-col justify-center">
                <h3 className="font-semibold text-lg mb-4">Or Connect Directly</h3>
                <div className="flex flex-col gap-4">
                  <a 
                    href="mailto:zein@zeinalamah.com"
                    className="flex items-center gap-3 px-6 py-4 dark:bg-primary/10 dark:hover:bg-primary/20 bg-primary/5 hover:bg-primary/10 text-text rounded-lg transition-colors"
                    rel="noopener"
                  >
                    <Mail size={20} className="text-primary" />
                    <div className="text-left">
                      <div className="font-medium">Email</div>
                      <div className="text-sm text-text/60">zein@zeinalamah.com</div>
                    </div>
                  </a>
                  
                  <a 
                    href="https://www.linkedin.com/in/zein-alamah/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 px-6 py-4 dark:bg-primary/10 dark:hover:bg-primary/20 bg-primary/5 hover:bg-primary/10 text-text rounded-lg transition-colors"
                  >
                    <Linkedin size={20} className="text-primary" />
                    <div className="text-left">
                      <div className="font-medium">LinkedIn</div>
                      <div className="text-sm text-text/60">Connect professionally</div>
                    </div>
                  </a>
                  
                  <a 
                    href="https://x.com/zein_alamah"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 px-6 py-4 dark:bg-primary/10 dark:hover:bg-primary/20 bg-primary/5 hover:bg-primary/10 text-text rounded-lg transition-colors"
                  >
                    <FaXTwitter size={20} className="text-primary" />
                    <div className="text-left">
                      <div className="font-medium">X (Twitter)</div>
                      <div className="text-sm text-text/60">Follow for updates</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </AnimatedCard>
      </div>
    </section>
  );
};