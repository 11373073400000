// components/Navigation.tsx
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import { Logo } from '../shared/Logo';
import { MobileMenu } from '../ui/MobileMenu';
import { ThemeToggle } from '../ui/ThemeToggle';

interface NavItem {
  label: string;
  path?: string;
  action?: () => void;
  items?: { label: string; path: string }[];
}

export const Navigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  const scrollToSection = (sectionId: string) => {
    const goToSection = () => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(goToSection, 100);
    } else {
      goToSection();
    }
  };

  const navItems: NavItem[] = [
    { 
      label: 'Home',
      action: () => {
        if (location.pathname !== '/') {
          navigate('/');
        } else {
          scrollToSection('hero');
        }
      }
    },
    {
      label: 'About',
      action: () => scrollToSection('about'),
    },
    {
      label: 'Insights',
      items: [
        { label: 'Articles', path: '/blog' },
        { label: 'Projects', path: '/projects' },
        { label: 'Publications', path: '/publications' }
      ]
    },
    { label: 'Resume', path: '/resume' },
    {
      label: 'Contact',
      action: () => scrollToSection('contact'),
    }
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [activeDropdown]);

  const toggleDropdown = (label: string) => {
    setActiveDropdown(activeDropdown === label ? null : label);
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 bg-background shadow-sm z-50 h-20">
        <div className="max-w-[1920px] mx-auto px-8 lg:px-16">
          <nav className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex-none">
              <div 
                onClick={() => scrollToSection('hero')} 
                className="cursor-pointer"
              >
                <Logo />
              </div>
            </div>
            
            {/* Desktop Navigation - Right Aligned */}
            <div className="hidden md:flex items-center space-x-12">
              {navItems.map((item) => (
                <div
                  key={item.label}
                  className={`relative ${item.items ? 'dropdown' : ''}`}
                  ref={item.items ? dropdownRef : null}
                >
                  {item.items ? (
                    <button 
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the event from bubbling up to document
                        toggleDropdown(item.label);
                      }}
                      className="text-text hover:text-primary transition-colors flex items-center gap-2 py-2 text-[15px] font-medium cursor-pointer"
                    >
                      {item.label}
                      <ChevronDown
                        size={16}
                        className={`transition-transform duration-200 ${
                          activeDropdown === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                  ) : item.path ? (
                    <Link 
                      to={item.path}
                      className="text-text hover:text-primary transition-colors flex items-center gap-2 py-2 text-[15px] font-medium"
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <button 
                      onClick={() => {
                        item.action?.();
                      }}
                      className="text-text hover:text-primary transition-colors flex items-center gap-2 py-2 text-[15px] font-medium cursor-pointer"
                    >
                      {item.label}
                    </button>
                  )}

                  {/* Dropdown Menu */}
                  {item.items && activeDropdown === item.label && (
                    <div 
                      className={`absolute top-full left-0 mt-1 w-48 bg-background border border-primary/20 rounded-lg shadow-lg py-2`}
                      onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                    >
                      {item.items.map((subItem) => (
                        <Link
                          key={subItem.path}
                          to={subItem.path}
                          className="block px-4 py-2 hover:bg-primary/20 transition-colors text-text hover:text-primary text-[15px]"
                          onClick={() => {
                            setActiveDropdown(null);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              
              {/* Theme Toggle */}
              <ThemeToggle className="ml-4" />
            </div>

            {/* Mobile Menu Button and Theme Toggle */}
            <div className="md:hidden flex items-center gap-4">
              <ThemeToggle />
              <button 
                className="text-text p-2 hover:bg-primary/10 rounded-lg transition-colors"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </nav>
        </div>
      </header>
      
      {/* Mobile Menu moved outside of header */}
      <MobileMenu 
        isOpen={isMobileMenuOpen} 
        onClose={() => setIsMobileMenuOpen(false)} 
        navItems={navItems}
      />
    </>
  );
};
