import React, { createContext, useContext, useEffect, useState } from 'react';
import { Theme, darkTheme, lightTheme, applyTheme } from './theme';

/**
 * Theme Context Type Definition
 * Defines the shape of the theme context that will be provided to components
 */
type ThemeContextType = {
  theme: Theme;
  isDark: boolean;
  toggleTheme: () => void;
};

// Create the theme context with a default undefined value
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

/**
 * Initialize theme on page load
 * Prevents flash of incorrect theme by setting theme immediately
 * @returns {boolean} True if dark theme should be used as default
 */
const initializeTheme = (): boolean => {
  try {
    // Check localStorage for user preference, default to dark if not set
    const savedTheme = localStorage.getItem('theme');
    // Default to dark theme (true)
    const initialIsDark = savedTheme === 'light' ? false : true;
    
    // Set theme immediately
    applyTheme(initialIsDark ? darkTheme : lightTheme);
    document.documentElement.setAttribute('data-theme', initialIsDark ? 'dark' : 'light');
    
    console.log('Theme initialized:', initialIsDark ? 'dark' : 'light');
    return initialIsDark;
  } catch (error) {
    console.error('Error initializing theme:', error);
    // Default to dark theme on error
    applyTheme(darkTheme);
    document.documentElement.setAttribute('data-theme', 'dark');
    return true;
  }
};

// Initial theme determination - runs immediately
const initialIsDark = initializeTheme();

/**
 * Theme Provider Component
 * Manages theme state and provides theme context to the application
 */
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // State to track current theme (default: dark)
  const [isDark, setIsDark] = useState<boolean>(initialIsDark);
  // Current theme object based on isDark state
  const theme = isDark ? darkTheme : lightTheme;
  
  // Effect to update theme when it changes
  useEffect(() => {
    try {
      // Save theme preference to localStorage
      localStorage.setItem('theme', isDark ? 'dark' : 'light');
      
      // Apply theme to document
      applyTheme(theme);
      document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
      
      // Update color-scheme meta tag
      document.documentElement.style.colorScheme = isDark ? 'dark' : 'light';
      
      console.log('Theme applied:', isDark ? 'dark' : 'light');
    } catch (error) {
      console.error('Error applying theme:', error);
    }
  }, [isDark, theme]);
  
  // Toggle between dark and light themes
  const toggleTheme = () => {
    console.log('Toggling theme from', isDark ? 'dark' : 'light', 'to', !isDark ? 'dark' : 'light');
    setIsDark(prevIsDark => !prevIsDark);
  };
  
  // Context value to provide to consumers
  const contextValue: ThemeContextType = {
    theme,
    isDark,
    toggleTheme
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * useTheme Hook
 * Custom hook to access the theme context
 * @returns {ThemeContextType} The current theme context
 * @throws {Error} If used outside of a ThemeProvider
 */
export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
