import type { Publication } from '../../../types';
import CollaborationImage from "@Publication_images/collaboration-words-dict.webp";

const researchCollaboration: Publication = {
  id: 'research-collaboration',
  title: "The role of international research collaboration and faculty related factors in publication citations: Evidence from Lebanon",
  journal: "Economies",
  date: "13 March, 2023",
  link: "https://www.mdpi.com/2227-7099/11/3/90",
  abstract: "The importance of international collaboration in the research field is well-documented, but its impact on the number of citations received by research publications is not fully understood. This paper aims to fill this gap by examining the relationship between international collaboration and other factors and the number of citations for research publications. Using data from the Lebanese American University, the authors employ ordinary least squares estimation to analyze the impact of international collaboration on the number of citations while controlling for various publications, authorship, university, and socioeconomic factors. The results show that international collaboration has a statistically significant positive effect on the number of citations. Based on these findings, we recommend that institutions and researchers seeking to increase the number of citations for their publications should focus on building and maintaining strong international collaborations and publishing in high-quality journals. This paper provides evidence of the importance of international collaboration for researchers seeking to increase the reach and impact of their publications. It also provides insights into the relevance of other factors, such as the number of publications, age, and journal rank.",
  image: CollaborationImage,
  type: 'academic',
  tags: ['Research Collaboration', 'Citation Analysis', 'Higher Education']
};

export default researchCollaboration;













