import { motion } from 'framer-motion';
import { Logo } from './Logo';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
  showLogo?: boolean;
}

export const LoadingSpinner = ({ size = 'large', showLogo = true }: LoadingSpinnerProps) => {
  // Size classes for different spinner sizes
  const sizeClasses = {
    small: 'w-8 h-8 border-2',
    medium: 'w-12 h-12 border-3',
    large: 'w-16 h-16 border-4'
  };

  // Container classes based on size and if it's full screen
  const containerClasses = size === 'large' && showLogo
    ? "fixed inset-0 bg-[#0E0F1F] flex flex-col items-center justify-center z-50"
    : "flex flex-col items-center justify-center";

  return (
    <div className={containerClasses}>
      {showLogo && size === 'large' && (
        <div className="mb-8">
          <Logo />
        </div>
      )}
      <motion.div
        className={`${sizeClasses[size]} border-[#2B3187]/20 border-t-[#2B3187] rounded-full`}
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        role="status"
        aria-label="Loading"
      />
      {size === 'large' && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-4 text-[#F7F8F8]/60"
        >
          Loading...
        </motion.p>
      )}
    </div>
  );
};