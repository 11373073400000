import React, { lazy, Suspense } from 'react';
import { LoadingSpinner } from '../components/shared/LoadingSpinner';

/**
 * Creates a lazily loaded component with a suspense fallback
 * 
 * @param importFn - The import function (e.g., () => import('./MyComponent'))
 * @param fallback - Optional custom fallback component
 * @returns A lazily loaded component wrapped in Suspense
 */
export function lazyLoadComponent<T extends React.ComponentType<any>>(
  importFn: () => Promise<{ default: T }>,
  fallback: React.ReactNode = <LoadingSpinner size="small" showLogo={false} />
) {
  const LazyComponent = lazy(importFn);
  
  return (props: React.ComponentProps<T>) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
}

/**
 * Prefetches a component for future use
 * 
 * @param importFn - The import function (e.g., () => import('./MyComponent'))
 */
export function prefetchComponent(importFn: () => Promise<any>) {
  // Start loading the component in the background
  importFn();
}
