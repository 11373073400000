/**
 * Security utilities for enhancing application security
 */

/**
 * Sanitizes user input to prevent XSS attacks
 * @param input - The user input to sanitize
 * @returns Sanitized string
 */
export const sanitizeInput = (input: string): string => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

/**
 * Validates a URL to ensure it's safe
 * @param url - The URL to validate
 * @returns Boolean indicating if the URL is safe
 */
export const isValidUrl = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    // Only allow specific protocols
    return ['http:', 'https:'].includes(parsedUrl.protocol);
  } catch (e) {
    return false;
  }
};

/**
 * Creates a nonce for CSP
 * @returns A random nonce string
 */
export const generateNonce = (): string => {
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);
  return Array.from(randomBytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
};

/**
 * Normalizes a file path to prevent path traversal attacks
 * @param path - The file path to normalize
 * @returns Normalized path string
 */
export const normalizePath = (path: string): string => {
  // Remove any instances of ".." to prevent directory traversal
  return path.replace(/\.\.\//g, '').replace(/\.\.\\/g, '');
};

/**
 * Creates HTTP security headers for fetch requests
 * @returns Headers object with security headers
 */
export const getSecurityHeaders = (): HeadersInit => {
  return {
    'Content-Security-Policy': "default-src 'self'",
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Referrer-Policy': 'strict-origin-when-cross-origin'
  };
};

/**
 * Checks if a token is expired
 * @param token - The JWT token to check
 * @returns Boolean indicating if the token is expired
 */
export const isTokenExpired = (token: string): boolean => {
  try {
    // Extract the payload part of the JWT
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));
    
    // Check if the token is expired
    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  } catch (e) {
    // If there's any error parsing the token, consider it expired
    return true;
  }
};
