// main.tsx
import { StrictMode, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './components/shared/ErrorBoundary';
import { ThemeProvider } from './theme/ThemeContext';
import App from './App';
import './index.css';

// The Root component handles app initialization
const Root = () => {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    // Handle service worker updates
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.update();
      }).catch(error => {
        console.error('Service worker update failed:', error);
      });
    }

    // Handle cache versioning
    const CACHE_VERSION = 'v1';
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          if (!cacheName.includes(CACHE_VERSION)) {
            window.caches.delete(cacheName);
          }
        });
      }).catch(error => {
        console.error('Cache management error:', error);
      });
    }

    // App is ready
    setIsAppReady(true);
    
    // Hide the loading spinner
    const spinner = document.getElementById('loading-spinner');
    if (spinner) {
      spinner.style.display = 'none';
    }
  }, []);

  // Render app when ready
  if (!isAppReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found');
}

// Render the React app
createRoot(rootElement).render(
  <StrictMode>
    <Root />
  </StrictMode>
);
