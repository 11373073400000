import { Calendar, Clock, Tag, BookOpen } from 'lucide-react';

interface CardMetaProps {
  date: string;
  readTime?: string;
  tags: string[];
  journal?: string;
}

export const CardMeta = ({ date, readTime, tags, journal }: CardMetaProps) => {
  if (!tags || tags.length === 0) {
    tags = []; // Ensure tags is always an array
  }
  
  return (
    <div className="mt-auto">
      <div className="flex flex-wrap items-center gap-4 text-sm text-text/60 mb-4">
        <div className="flex items-center gap-1">
          <Calendar size={16} className="text-text/70" />
          {date}
        </div>
        {readTime && (
          <div className="flex items-center gap-1">
            <Clock size={16} className="text-text/70" />
            {readTime}
          </div>
        )}
        {journal && (
          <div className="flex items-center gap-1">
            <BookOpen size={16} className="text-text/70" />
            {journal}
          </div>
        )}
      </div>
      
      {tags.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={`${tag}-${index}`}
              className="inline-flex items-center px-3 py-1.5 rounded-lg bg-primary text-white dark:bg-white dark:text-primary font-semibold text-sm hover:bg-primary/90 dark:hover:bg-white/90 transition-colors shadow-sm"
            >
              <Tag size={12} className="mr-1.5 text-primary/80 dark:text-primary/80" />
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};