import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CheckCircle, XCircle, ToggleLeft, ToggleRight } from 'lucide-react';
import { Link } from 'react-router-dom';

declare global {
  interface Window {
    enableAnalytics: () => void;
    manageCookieSettings: () => void;
  }
}

// Create a reference to the cookie manager state
let setShowManagerGlobal: React.Dispatch<React.SetStateAction<boolean>> | null = null;

// Expose a global method to open cookie settings
window.manageCookieSettings = function() {
  if (setShowManagerGlobal) {
    setShowManagerGlobal(true);
  }
};

export const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showManager, setShowManager] = useState(false);
  const [cookiesEnabled, setCookiesEnabled] = useState<boolean | null>(null);
  
  // Store the state setter in the global reference
  useEffect(() => {
    setShowManagerGlobal = setShowManager;
    
    return () => {
      // Clean up when component unmounts
      setShowManagerGlobal = null;
    };
  }, []);

  // Load cookie status on mount
  useEffect(() => {
    const cookieStatus = localStorage.getItem('cookiesAccepted');
    if (cookieStatus === 'true') {
      setCookiesEnabled(true);
    } else if (cookieStatus === 'false') {
      setCookiesEnabled(false);
    } else {
      setCookiesEnabled(null);
    }
  }, []);

  useEffect(() => {
    // Check for consent on mount with a small delay
    const timer = setTimeout(() => {
      const hasConsent = localStorage.getItem('cookiesAccepted');
      if (hasConsent === null) {
        // Only show if user hasn't made a choice yet
        setIsVisible(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const toggleCookies = () => {
    const newStatus = !cookiesEnabled;
    setCookiesEnabled(newStatus);
    localStorage.setItem('cookiesAccepted', newStatus ? 'true' : 'false');
    
    if (newStatus) {
      // Enable analytics if cookies are being enabled
      if (window.enableAnalytics) {
        window.enableAnalytics();
      }
    }
    
    // Close the banner if it's the initial choice
    if (isVisible) {
      setIsVisible(false);
    }
  };
  
  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesEnabled(true);
    setIsVisible(false);
    
    // Call the function we exposed in index.html
    if (window.enableAnalytics) {
      window.enableAnalytics();
    }
  };
  
  const rejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setCookiesEnabled(false);
    setIsVisible(false);
  };
  
  // Cookie settings manager component
  const CookieManager = () => {
    // Get status text and colors based on current cookie setting
    const getStatusDetails = () => {
      if (cookiesEnabled === true) {
        return {
          text: 'enabled',
          bgColor: 'bg-green-500/10',
          textColor: 'text-green-500',
          icon: <CheckCircle className="inline-block mr-1" size={16} />
        };
      } else if (cookiesEnabled === false) {
        return {
          text: 'disabled',
          bgColor: 'bg-red-500/10',
          textColor: 'text-red-500',
          icon: <XCircle className="inline-block mr-1" size={16} />
        };
      } else {
        return {
          text: 'not set',
          bgColor: 'bg-yellow-500/10',
          textColor: 'text-yellow-500',
          icon: null
        };
      }
    };
    
    const statusDetails = getStatusDetails();
    
    return (
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="fixed bottom-0 left-0 right-0 bg-background border-t border-primary/20 p-4 z-50"
      >
        <div className="container mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-text font-bold">Cookie Settings</h3>
            <button
              onClick={() => setShowManager(false)}
              className="text-text/60 hover:text-text transition-colors outline-none"
              aria-label="Close cookie settings"
            >
              <X size={20} />
            </button>
          </div>
          
          <div className="mb-6 text-text/80 text-sm">
            <p className="mb-3">
              This website uses Google Analytics to understand how visitors interact with our content. 
              Analytics cookies are <span className={`font-semibold ${statusDetails.textColor} px-2 py-1 rounded ${statusDetails.bgColor}`}>
                {statusDetails.icon} {statusDetails.text}
              </span>
            </p>
            <p>
              You can read more about how we use cookies in our{' '}
              <Link to="/cookie-policy" className="text-primary hover:text-text transition-colors">
                Cookie Policy
              </Link>.
            </p>
          </div>
          
          <div className="flex items-center justify-between">
            <span className="text-text/80">Analytics Cookies</span>
            <button
              onClick={toggleCookies}
              className="outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-background rounded-lg transition-colors"
              aria-label={cookiesEnabled ? "Disable analytics cookies" : "Enable analytics cookies"}
            >
              {cookiesEnabled ? (
                <ToggleRight size={32} className="text-primary" />
              ) : (
                <ToggleLeft size={32} className="text-text/50" />
              )}
            </button>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-0 left-0 right-0 bg-background border-t border-primary/20 p-4 z-50"
          >
            <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
              <div className="text-text/80 text-sm sm:text-base">
                <p>
                  This website uses Google Analytics cookies to analyze site traffic. 
                  By clicking "Accept," you consent to our use of analytics cookies.{' '}
                  <Link 
                    to="/cookie-policy" 
                    className="text-primary hover:text-text inline-block"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Learn more
                  </Link>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <button
                  onClick={acceptCookies}
                  className="px-5 py-2 bg-primary text-white rounded-lg hover:opacity-90 transition-colors text-sm outline-none"
                >
                  Accept
                </button>
                <button
                  onClick={rejectCookies}
                  className="px-5 py-2 bg-text/10 text-text rounded-lg hover:bg-text/20 transition-colors text-sm outline-none"
                >
                  Reject
                </button>
                <button
                  onClick={() => setIsVisible(false)}
                  className="text-text/60 hover:text-text transition-colors outline-none"
                  aria-label="Close cookie consent"
                >
                  <X size={20} />
                </button>
              </div>
            </div>
          </motion.div>
        )}
        
        {showManager && <CookieManager />}
      </AnimatePresence>
    </>
  );
};