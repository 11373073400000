import type { Project } from '../../../types';
import PortfolioOptimizationImage from "@Project_images/Portfolio Optimization (ETH-USD).webp";

const portfolioOptimization: Project = {
  id: "portfolio-optimization",
  title: "Portfolio Optimization and Performance Analysis",
  description: "Exploring portfolio optimization and risk-return tradeoffs through Python-driven financial modeling and data visualization.",
  technologies: ["Python", "Pandas", "NumPy", "Matplotlib", "Seaborn", "Jupyter Notebook", "Data Analysis"],
  image: PortfolioOptimizationImage,
  date: "December 15, 2020",
  content: `
    <div class="project-content space-y-6">
      <h2 class="text-2xl font-bold text-text">Portfolio Optimization and Performance Analysis</h2>
      <p class="text-text leading-relaxed">
        This project, developed as part of my Master's thesis in Finance, marked the first time I deeply explored cryptocurrency markets, simulations, and research. It played a fundamental role in shaping and sharpening my skills and interests in data analysis, coding, and research. The work bridges advanced financial theory with practical applications, offering actionable insights into investment strategy design and evaluation.
      </p>
  
      <h3 class="text-xl font-semibold text-text">Key Highlights</h3>
      <ul class="list-disc pl-5 space-y-2 text-text">
        <li><strong>Portfolio Optimization:</strong> Techniques include Monte Carlo simulations, efficient frontier construction, and optimization for maximum Sharpe ratio and minimum variance portfolios.</li>
        <li><strong>Performance Metrics:</strong> Analyzed Sharpe Ratio, Treynor Ratio, Jensen's Alpha, and Beta for risk-adjusted performance.</li>
        <li><strong>Data Visualization:</strong> Created efficient frontier plots, allocation charts, and heatmaps for correlation matrices.</li>
        <li><strong>Statistical Testing:</strong> Conducted robust T-tests to compare portfolio performance against benchmarks.</li>
      </ul>
  
      <h3 class="text-xl font-semibold text-text">Challenges Addressed</h3>
      <p class="text-text leading-relaxed">
        The project overcame significant challenges, including:
      </p>
      <ul class="list-disc pl-5 space-y-2 text-text">
        <li>Managing and processing complex datasets.</li>
        <li>Bridging theoretical finance with practical Python-based applications.</li>
        <li>Conducting statistically valid comparisons of portfolio strategies.</li>
      </ul>
  
      <h3 class="text-xl font-semibold text-text">Practical Applications</h3>
      <ul class="list-disc pl-5 space-y-2 text-text">
        <li><strong>For Investors:</strong> Optimize portfolios and analyze risk-return tradeoffs.</li>
        <li><strong>For Researchers:</strong> A resource for exploring portfolio theory.</li>
        <li><strong>For Learners:</strong> A guide to understanding financial modeling and visualization.</li>
      </ul>
  
      <p class="text-text leading-relaxed">
        Explore the complete code and visualizations:
        <a
          href="https://github.com/zeinalamah/Portfolio-Analysis-Project"
          target="_blank"
          class="text-primary font-semibold hover:underline"
        >
          View on GitHub
        </a>.
      </p>
    </div>
  `
};

export default portfolioOptimization; 